import React, { useEffect, useRef, useState } from 'react';
import Header from './header';
import './searchPage.css';
import noUiSlider from 'nouislider';
import './nouislider.css';
import { fetchProperties, fetchBanner, fetchRooms } from '../api/api';  // Обновленный путь

const SearchPage = () => {
  const sliderRef = useRef(null);
  const [guestServices, setGuestServices] = useState([]);
  const [roomFeatures, setRoomFeatures] = useState([]);
  const [selectedGuestServices, setSelectedGuestServices] = useState([]);
  const [selectedRoomFeatures, setSelectedRoomFeatures] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const properties = await fetchProperties();
        
        const guestServices = properties.filter(property => property.type === 'guest_service');
        const roomFeatures = properties.filter(property => property.type === 'room_feature');

        setGuestServices(guestServices);
        setRoomFeatures(roomFeatures);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getBanner = async () => {
      try {
        const bannerData = await fetchBanner();
        setBanner(bannerData);
      } catch (error) {
        setBanner(null);
      }
    };

    getBanner();
  }, []);

  const getRooms = async (properties = '', price = '') => {
    setLoading(true);
    try {
      const roomsData = await fetchRooms(properties, price);
      setRooms(roomsData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRooms();
  }, []);

  useEffect(() => {
    if (sliderRef.current && !sliderRef.current.noUiSlider) {
      noUiSlider.create(sliderRef.current, {
        start: [10, 10000],
        connect: true,
        range: {
          min: 10,
          max: 10000
        }
      });

      const priceRangeValueElement = document.getElementById('price-range-value');
      sliderRef.current.noUiSlider.on('update', function (values, handle) {
        priceRangeValueElement.innerHTML = 'US$' + Math.round(values[0]) + ' — US$' + Math.round(values[1]) + '+';
      });
    }
  }, [loading]); // Добавлен массив зависимостей, чтобы слайдер инициализировался после загрузки данных

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.matches('.dropbtn')) {
        const dropdowns = document.getElementsByClassName('dropdown-content');
        for (let i = 0; i < dropdowns.length; i++) {
          const openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    document.getElementById('dropdown-content').classList.toggle('show');
  };

  const handleFeatureChange = (id) => {
    setSelectedRoomFeatures(prevState => 
      prevState.includes(id) ? prevState.filter(feature => feature !== id) : [...prevState, id]
    );
  };

  const handleServiceChange = (id) => {
    setSelectedGuestServices(prevState => 
      prevState.includes(id) ? prevState.filter(service => service !== id) : [...prevState, id]
    );
  };

  const applyFilters = () => {
    const priceRange = sliderRef.current.noUiSlider.get().map(value => Math.round(value)).join('-');
    const properties = [...selectedRoomFeatures, ...selectedGuestServices].join('-');
    getRooms(properties, priceRange);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <Header />
      <div className="ads">
        {banner && banner.image ? (
          <img src={banner.image} alt="Banner" />
        ) : (
          <div className="placeholder-banner">
            <div>Your advertisement could be here.</div>
            <div>Contact us at <span className="red-text">4rooms@no-reply.qt</span></div>
          </div>
        )}
      </div>
      <div className="container">
        <div className="filters">
          <h2>Filters</h2>
          <div className="price-range">
            <label>Price range (per night)</label>
            <span id="price-range-value">US$10 — US$300+</span>
            <div id="price-range" ref={sliderRef}></div>
          </div>
          <h3>Room Features</h3>
          {roomFeatures.map((feature) => (
            <label key={feature.id}>
              <input type="checkbox" onChange={() => handleFeatureChange(feature.id)} /> {feature.name}
            </label>
          ))}
          <h3>Guest Services:</h3>
          {guestServices.map((service) => (
            <label key={service.id}>
              <input type="checkbox" onChange={() => handleServiceChange(service.id)} /> {service.name}
            </label>
          ))}
          <div className="buttons">
            <div className="dropdown">
              <button className="dropbtn" onClick={toggleDropdown}>sort by ▼</button>
              <div className="dropdown-content" id="dropdown-content">
                <a href="#">Price Down</a>
                <a href="#">Price Up</a>
                <a href="#">Liked</a>
              </div>
            </div>
            <button className="applybtn" onClick={applyFilters}>Apply filters</button>
          </div>
        </div>

        <div className="available-now">
          <h2>Available now</h2>
          {rooms.length > 0 ? (
            rooms.map(room => (
              <div className="room-card" key={room.room_id}>
                <div className="image-container">
                  <img src={room.room_image || "https://via.placeholder.com/255x255"} alt="Room Image" />
                  <div className="like-circle">
                    <i className="fas fa-heart"></i>
                  </div>
                </div>
                <div className="room-info">
                  <h3>{room.room_name}</h3>
                  <p>{room.room_description || "Description not available"}<br />  <button className="more-btn">More &#x27A5;</button></p>
                </div>
                <div className="room-features-price">
                  <div className="room-features">
                    <h4>Room Features</h4>
                    <ul>
                      {room.room_features.map((feature, index) => (
                        <li key={index}><i className="fas fa-bed"></i> {feature}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <div className="room-price">Цена: ${room.room_price}</div>
                    <button className="book-now">Book now</button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="placeholder-message">
              <div className="placeholder-box">
                <p>
                  <span className="unavailable-text">Unfortunately</span>, we were unable to find any rooms that match your search criteria. Please try <span className="highlight-text">adjusting your search settings</span> or <span className="highlight-text">selecting different dates</span> for your stay.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchPage;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './header.css';
import logo from './assets/logo.svg';
import guestIcon from './assets/guest.svg';

const Header = () => {
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [date, setDate] = useState(new Date(2024, 3)); // Апрель 2024
  const navigate = useNavigate();

  const toggleCalendar = () => setCalendarVisible(!calendarVisible);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
    console.log('Dropdown visibility toggled:', !dropdownVisible);
  };

  const changeQuantity = (type, delta) => {
    if (type === 'adults') {
      setAdults(Math.max(0, adults + delta));
    } else if (type === 'children') {
      setChildren(Math.max(0, children + delta));
    } else if (type === 'rooms') {
      setRooms(Math.max(0, rooms + delta));
    }
  };

  const applyDateSelection = () => {
    setCalendarVisible(false);
  };

  const resetDateSelection = () => {
    // Логика сброса даты
  };

  const acceptSelection = () => {
    setDropdownVisible(false);
  };

  const nextMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() + 1));
  };

  const prevMonth = () => {
    setDate(new Date(date.getFullYear(), date.getMonth() - 1));
  };

  const renderDays = () => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const firstDayOfMonth = (new Date(year, month, 1).getDay() + 6) % 7; // Корректировка для начала недели с понедельника
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(<div key={`day-${i}`} className="calendar-day">{i}</div>);
    }

    return days;
  };

  const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

  const handleSearch = () => {
    navigate('/search');
  };

  return (
    <div className="header">
      <div className="header-content">
        <div className="logo-container">
          <img src={logo} alt="4ROOMSHOTEL" className="logo" />
        </div>
        <div className="booking-bar">
          <div className="custom-datepicker-wrapper">
            <input
              type="text"
              id="date-range"
              className="date-range-input"
              placeholder="Выберите даты"
              readOnly
              onClick={toggleCalendar}
            />
            <div id="custom-datepicker" className={`custom-datepicker ${calendarVisible ? 'visible' : ''}`}>
              <div className="calendar-header">
                <button onClick={prevMonth}>&#9664;</button>
                <span id="calendar-month-year">{`${monthNames[date.getMonth()]} ${date.getFullYear()}`}</span>
                <button onClick={nextMonth}>&#9654;</button>
              </div>
              <div className="calendar-weekdays">
                <div>Пн</div>
                <div>Вт</div>
                <div>Ср</div>
                <div>Чт</div>
                <div>Пт</div>
                <div>Сб</div>
                <div>Вс</div>
              </div>
              <div className="calendar-grid" id="calendar-grid">
                {renderDays()}
              </div>
              <div className="calendar-footer">
                <button onClick={applyDateSelection}>Принять</button>
                <button onClick={resetDateSelection}>Сбросить</button>
              </div>
            </div>
          </div>
          <div className="guests-input" onClick={toggleDropdown}>
            <img src={guestIcon} alt="guest" className="logo" style={{ marginRight: '10px' }} />
            <span className="guests-text">{`${adults} взрослых • ${children} детей • ${rooms} комнат`}</span>
            <div className={`dropdown-content ${dropdownVisible ? 'visible' : ''}`} id="dropdown-content">
              <div className="quantity-selector" data-type="adults">
                <span>Взрослые</span>
                <div className="controls">
                  <button onClick={() => changeQuantity('adults', -1)}>-</button>
                  <span id="adults-number">{adults}</span>
                  <button onClick={() => changeQuantity('adults', 1)}>+</button>
                </div>
              </div>
              <div className="quantity-selector" data-type="children">
                <span>Дети</span>
                <div className="controls">
                  <button onClick={() => changeQuantity('children', -1)}>-</button>
                  <span id="children-number">{children}</span>
                  <button onClick={() => changeQuantity('children', 1)}>+</button>
                </div>
              </div>
              <div className="quantity-selector" data-type="rooms">
                <span>Комнаты</span>
                <div className="controls">
                  <button onClick={() => changeQuantity('rooms', -1)}>-</button>
                  <span id="rooms-number">{rooms}</span>
                  <button onClick={() => changeQuantity('rooms', 1)}>+</button>
                </div>
              </div>
              <button className="accept-button" onClick={acceptSelection}>Принять</button>
            </div>
          </div>
          <button className="search-button" onClick={handleSearch}>Найти</button>
        </div>
        <div className="user-buttons">
          <button className="register-button">Зарегистрироваться</button>
          <button className="login-button">Войти в аккаунт</button>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Импортируйте Routes вместо Switch
import MainPage from './components/mainPage';
import SearchPage from './components/searchPage';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/search" element={<SearchPage />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from 'react';
import Header from './header';
import mainImage from './assets/Hotel.png';
import barImage from './assets/Icebucket.png';
import tvImage from './assets/TV.png';
import phoneImage from './assets/Phone.png';
import './mainPage.css';

const MainPage = () => {
  return (
    <div>
      <Header />
      <div className="main-img" style={{ backgroundImage: `url(${mainImage})` }}>
        <h1 className="h1-positioned">WELCOME</h1>
        <p className="hotel-desc">
          <span>4ROOMS</span> is a good place for vacation with your friends or family
        </p>
      </div>
      <div className="main-content">
        <span className="before-cards">Our features and services</span>
        <div className="cards">
          <div className="card">
            <div className="card-img">
              <img src={barImage} alt="Bar" />
            </div>
            <div className="card-text">
              <h3>Bar</h3>
              <p>Don't want to leave the comfort of your room? We will gladly deliver your favorite drinks right to you. Enjoy your favorite Cristal champagne without leaving your room, and let us take care of your relaxation. Every sip is a delight, every moment is our care for you.</p>
            </div>
          </div>

          <div className="card">
            <div className="card-img">
              <img src={phoneImage} alt="24/7 in touch" />
            </div>
            <div className="card-text">
              <h3>24/7 in touch</h3>
              <p>Regardless of the time of day, you can always count on our support. Want to order drinks to your room, book a tour, or simply need some advice? Our concierge is always available, ready to respond to any of your requests and make your stay with us as pleasant as possible.</p>
            </div>
          </div>

          <div className="card">
            <div className="card-img">
              <img src={tvImage} alt="TV" />
            </div>
            <div className="card-text">
              <h3>TV</h3>
              <p>The rooms have modern televisions with a wide selection of channels for all ages. For children, it's a real treat! Numerous kids' channels with cartoons, educational programs, and entertainment shows will make their stay interesting and enjoyable. Let your children enjoy their favorite shows while you relax or take care of your own activities.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
